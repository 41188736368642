import { useEffect, useState } from 'react'
import {
  Box,
  Chip,
  Container,
  Autocomplete,
  TextField,
  Paper,
  List,
  ListItem,
  IconButton,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { getBusinessesByService, getServices } from '../../firebaseApp'

function SearchBusinesses(props) {
  const multiple = false
  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState(multiple ? [] : '')
  const [businesses, setBusinesses] = useState([])

  const loadBusinesses = () => {
    getBusinessesByService(multiple ? selectedService[0] : selectedService).then((businesses) => {
      setBusinesses(businesses)
    })
  }
  
  const onSubmitSearch = (event) => {
    event.preventDefault()

    loadBusinesses()
  }

  useEffect(() => {
    getServices().then((services) => {
      setServices(services.map(service => service.name))
    })
  }, [])

  useEffect(() => {
    loadBusinesses()
  }, [selectedService])

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        <Grid xs={12} sm={10} smOffset={1} md={8} mdOffset={2}>
          <Box component="form" onSubmit={onSubmitSearch} noValidate autoComplete="off" sx={{ margin: '0 auto', display: 'flex' }}>
            <Autocomplete
              freeSolo={multiple}
              fullWidth
              filterSelectedOptions
              multiple={multiple}
              disableClearable
              value={selectedService}
              onChange={(_, value) => {
                if (multiple) {
                  setSelectedService([value[1]])
                } else {
                  setSelectedService(value)
                }
              }}
              options={services}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip size="small" variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => {
                const inputProps = { ...params.InputProps, disableUnderline: true }
                return (
                  <Paper sx={{ p: '0 4px', display: 'flex', alignItems: 'center' }}>
                    <TextField {...params} placeholder="Search by service" sx={{ ml: 1, flex: 1 }} variant="standard" InputProps={inputProps} />
                    <IconButton type="submit" sx={{ p: '10px' }}>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                )
              }}
            />
          </Box>
          <List>
            {businesses.map((business) => {
              return (
                <ListItem sx={{ display: 'block' }}>
                  <Typography variant="h4">{business.name}</Typography>
                  <Typography variant="body1">
                    <pre>{JSON.stringify(business, undefined, 2)}</pre>
                  </Typography>
                </ListItem>
              )
            })}
          </List>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SearchBusinesses
