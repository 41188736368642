// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFirestore, collection, getDocs, where, query } from 'firebase/firestore/lite'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAGGIQyopiB-cnzC39yNr2FnFOamZ0fnVI",
  authDomain: "whodo-beta.firebaseapp.com",
  projectId: "whodo-beta",
  storageBucket: "whodo-beta.appspot.com",
  messagingSenderId: "257231989535",
  appId: "1:257231989535:web:de838a01b4b05b7a629e2a",
  measurementId: "G-JCYZJTL4WC"
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const db = getFirestore(app)

export async function getServices() {
  const servicesCollection = collection(db, 'services')
  const servicesSnapshot = await getDocs(servicesCollection)
  const servicesList = servicesSnapshot.docs.map(doc => doc.data())
  return servicesList
}

export async function getBusinessesByService(service) {
  const businessesCollection = collection(db, 'businesses')
  const businessQuery = query(businessesCollection, where("services", "array-contains", service))
  const businessSnapshot = await getDocs(businessQuery)
  const businessList = businessSnapshot.docs.map(doc => doc.data())
  return businessList
}

getBusinessesByService('Kitchen Remodeling')

// Initialize Firebase
function initFirebase() {  
  return { app, analytics, db }
}

export default initFirebase
