import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  AppBar as MaterialAppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material'
import { GoogleAuthProvider, getAuth, signInWithPopup, onAuthStateChanged } from 'firebase/auth'


import MenuIcon from '@mui/icons-material/Menu'
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyAGGIQyopiB-cnzC39yNr2FnFOamZ0fnVI",
  authDomain: "whodo-beta.firebaseapp.com",
  projectId: "whodo-beta",
  storageBucket: "whodo-beta.appspot.com",
  messagingSenderId: "257231989535",
  appId: "1:257231989535:web:de838a01b4b05b7a629e2a",
  measurementId: "G-JCYZJTL4WC"
}
initializeApp(firebaseConfig)
const firebaseAuth = getAuth()
firebaseAuth.useDeviceLanguage()

function AppBar() {
  const [user, setUser] = useState(() => firebaseAuth.currentUser)
  const [loading, setLoading] = useState(false)

  const signIn = async () => {
    setLoading(true)
    const provider = new GoogleAuthProvider()

    try {
      await signInWithPopup(firebaseAuth, provider)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const signOut = async () => {
    setLoading(true)

    try {
      await firebaseAuth.signOut()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const unsub = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setUser(user)
      } else {
        setUser(null)
      }
    })

    return unsub
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MaterialAppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            WhoDo
          </Typography>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => {
              if (user) {
                signOut()
              } else {
                signIn()
              }
            }}
          >
            {user ? 'Sign Out' : 'Sign In'}
          </LoadingButton>
        </Toolbar>
      </MaterialAppBar>
    </Box>
  )
}

export default AppBar
