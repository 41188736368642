import React from 'react'
import { Outlet } from 'react-router-dom'
import AppBar from './features/common/components/AppBar'


function App() {
  return (
    <>
      <AppBar />
      <Outlet />
    </>
  )
}

export default App
